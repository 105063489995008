import React, { useState, useEffect } from "react";
import { IconButton, Tooltip } from "@chakra-ui/react";
import { ChevronUpIcon } from "@chakra-ui/icons";

const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const ScrollToTop = (props) => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  return (
    <>
      {showTopBtn && (
        <Tooltip label="Back To Top">
          <IconButton
            icon={<ChevronUpIcon />}
            onClick={scrollTop}
            position="fixed"
            bottom="40px"
            right="40px"
            size="lg"
            variant="outline"
            colorScheme="teal"
            isRound
          />
        </Tooltip>
      )}
    </>
  );
};

export default ScrollToTop;
