import React from "react";
import {
  Flex,
  Box,
  Link as ChakraLink,
  List,
  ListItem,
  Text,
  Spacer,
  Center,
  Image,
  Stack,
} from "@chakra-ui/react";
import { Link } from "gatsby";

import ScrollToTop from "./ScrollToTop";

const Footer = (props) => {
  return (
    <Stack backgroundColor="#f9f9f9" zIndex="10">
      <Flex borderWidth="1px" borderRadius="lg" p="10">
        <Box>
          <List align="right">
            <ListItem>
              <Link to="/faqs">
                <u>FAQ</u>
              </Link>
            </ListItem>
            <ListItem>
              <Link to="/about">
                <u>ABOUT</u>
              </Link>
            </ListItem>

            <ListItem>
              <ChakraLink href="https://twitter.com/micropub7n" isExternal>
                TWITTER
              </ChakraLink>
            </ListItem>
            <ListItem>
              <Link to="/contact-us">
                <u>CONTACT</u>
              </Link>
            </ListItem>
            <ListItem>
              <Link to="/about/for-authors">
                <u>FOR AUTHORS</u>
              </Link>
            </ListItem>
            <ListItem>
              <Link to="/about/for-reviewers">
                <u>FOR REVIEWERS</u>
              </Link>
            </ListItem>
            <ListItem>
              <ChakraLink href="https://portal.micropublication.org" isExternal>
                SUBMIT A MICROPUBLICATION
              </ChakraLink>
            </ListItem>
          </List>
        </Box>
        <Spacer />

        <Box align="center">
          <Text>
            <i>microPublication Biology</i> is published by
          </Text>
          <ChakraLink
            href="https://library.caltech.edu/publish/publishing-program"
            isExternal
          >
            <Image src="/static/images/caltechlibrary-logo.png" height="60px" />
          </ChakraLink>
          <Text>1200 E. California Blvd. MC 1-43 Pasadena, CA 91125</Text>
        </Box>
        <Spacer />
        <Box align="center">
          <Text>The microPublication project is supported by</Text>
          <ChakraLink href="http://www.nlm.nih.gov/" isExternal>
            <Image src="/static/images/NIH_NLM_ABRV_2C_4.png" height="60px" />
          </ChakraLink>
          <Text>
            The National Institute of Health -- Grant #: 1U01LM012672-01
          </Text>
        </Box>
      </Flex>
      <Center p="10">microPublication Biology:ISSN: 2578-9430</Center>
      <ScrollToTop />
    </Stack>
  );
};

export default Footer;
